<template>
  <section id="inventory">
    <b-container fluid>
      <b-row style="margin-bottom: 20px">
        <b-col class="align_center">
          <h2 class="section_title" data-aos="fade-down" data-aos-duration="800">Global inventory</h2>
          <hr class="sep_title" />
          <div class="sep-30"></div>
          <p class="inventory_title" v-if="$store.state.carbonData.last_update !== ''">
            Last update:
            <b class="primary_color">{{ $store.state.carbonData.last_update }}</b>
          </p>
          <p class="inventory_subtitle"><b class="primary_color">Last 30 days (CO2)</b> - MoM Change</p>
        </b-col>
      </b-row>
      <b-row
        data-aos="fade"
        data-aos-duration="800"
        class="row_inventory"
        v-for="(inventory, index) in $store.state.carbonData.inventoryDataToRender"
        :key="index"
        v-bind:class="[$store.state.carbonData.inventoryDataToRender.length > 1 && index < $store.state.carbonData.inventoryDataToRender.length - 1 ? 'row_inventory_bordered' : '']"
      >
        <b-col md="2" class="col_region">
          <p class="inventory_region">{{ inventory.region }}</p>
          <hr class="sep_title" />
        </b-col>
        <b-col class="card_inventory" v-for="(sector, index_sector) in inventory.values" :key="index_sector">
          <div class="inner_card_inventory" v-bind:class="[sector.abs_change > 0 ? 'card_inventory_red' : 'card_inventory_green']">
            <p class="card_sector">{{ sector.sector }}</p>
            <p class="card_abs_change">{{ sector.abs_change }} Mt</p>
            <p class="card_rel_change">{{ sector.rel_change }}%</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
          <p class="mention">* Aviation data powered by FlightAware</p>
        </b-col>
      </b-row>
    </b-container>

    <div class="sep-30"></div>
    <div class="sep-30"></div>
    <b-container class="container_download_dataset" data-aos="fade-up" data-aos-duration="800">
      <b-row class="row_download_dataset">
        <b-col md="8">
          <p><b>Download our dataset</b></p>
        </b-col>
        <b-col md="4">
          <p style="margin-top: 0">
            <a download href="files/data.csv"
              ><b-button><b-icon icon="download" aria-hidden="true"></b-icon> Monthly Carbon Emissions </b-button></a
            >
          </p>
        </b-col>
      </b-row>
    </b-container>

    <div class="sep-30"></div>
    <div class="sep-30"></div>
    <b-container>
      <b-row>
        <b-col>
          <p class="cta_get_in_touch">
            Interested in getting the updated data?
            <a href="https://www.kayrros.com/contact-us/?utm_campaign=carbon_watch_open_data" target="_blank">Get in touch</a>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
