<template>
  <!-- PRODUCT INTRODUCTION SECTION -->
  <section id="introduction">
    <b-container>
      <b-row>
        <b-col md="12">
          <h2
            class="section_title"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            Carbon Watch - why make the data public
          </h2>
          <hr class="sep_title" />
          <div class="sep-30"></div>
          <p class="introduction_text">
            To solve the climate crisis, the world needs better atmospheric
            measurements. This is especially true of carbon dioxide, or CO2,
            which makes up three-quarters of total greenhouse gas emissions.
            Kayrros Carbon Watch is an open-access platform that gives realtime
            measurements of country-level carbon emissions for all key sectors:
            electricity, heavy industry, transportation and residential use.
            These data are based on the latest advances in satellite technology.
            Use them to track global progress in delivering the Glasgow and
            Paris pledges, compare CO2 emissions by country or sector, and help
            map new pathways to a more sustainable, lower-carbon future.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
