<template>
  <div class="content">
    <HeroHeader></HeroHeader>
    <Partners></Partners>
    <Introduction></Introduction>
    <ECCB></ECCB>
    <CTASection></CTASection>
    <FiltersSeparator></FiltersSeparator>
    <DataVisualization></DataVisualization>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader.vue";
import Partners from "@/components/Partners.vue";
import Introduction from "@/components/Introduction.vue";
import ECCB from "@/components/ECCB.vue";
import CTASection from "@/components/CTASection.vue";
import FiltersSeparator from "@/components/FiltersSeparator.vue";
import DataVisualization from "@/components/DataVisualization.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    HeroHeader,
    Partners,
    Introduction,
    FiltersSeparator,
    DataVisualization,
    Footer,
    ECCB,
    CTASection,
  },
  name: "Home",
};
</script>
