export class CarbonData {
  constructor() {
    this.raw_monthly_emissions = [];
    this.raw_eccb = [];
    this.list_regions = [];
    this.eccbdata = [];
    this.eccb_categories = [];
    this.selected_regions = [
      {
        name: "Europe",
        value: "Europe",
      },
    ];
    this.list_sectors = [
      { value: "power", text: "Power" },
      { value: "industry", text: "Industry" },
      { value: "ground_transport", text: "Ground transport" },
      { value: "domestic_aviation", text: "Domestic aviation *" },
      {
        value: "total_ets_emissions",
        text: "ETS Total",
      },

      { value: "total_co2_emissions", text: "Total CO2 Emissions" },
    ];
    this.selected_sectors = ["power"];
    this.graphDataToRender = [];
    this.inventoryDataToRender = [];
    this.categories = [];
    this.last_update = "";
  }

  /* Fetch list of dates for y-axis in highcharts, named categories */
  getCategories() {
    this.categories = [];
    for (var i = 0; i < this.raw_monthly_emissions.length; i++) {
      if (!this.categories.includes(this.raw_monthly_emissions[i]["value_date"])) {
        this.categories.push(this.raw_monthly_emissions[i]["value_date"]);
      }
    }
  }

  /* Fetch list of series based on user selection (regions and sectors) */
  getGraphData() {
    this.graphDataToRender = [];
    for (var i = 0; i < this.selected_regions.length; i++) {
      for (var j = 0; j < this.selected_sectors.length; j++) {
        var serie = this.getSerie(this.selected_regions[i].value, this.selected_sectors[j]);
        this.graphDataToRender.push(serie);
      }
    }
    return this.graphDataToRender;
  }

  /* Get a specific series of value for a [region,sector] */
  getSerie(region, sector) {
    let sector_name = this.getSectorName(sector);
    var serie = { name: region + " - " + sector_name, data: [] };

    var filtered = this.raw_monthly_emissions.filter((row) => row["country"] === region);

    for (var i = 0; i < filtered.length; i++) {
      if (filtered[i][sector] === "") {
        serie.data.push(null);
      } else {
        var serie_value = parseFloat(filtered[i][sector]);
        serie_value = serie_value / 1000000;
        serie_value = serie_value.toFixed(2);
        serie.data.push(parseFloat(serie_value));
      }
    }
    return serie;
  }

  /* Get global inventory datas based on user-selected regions */
  getTextData() {
    this.inventoryDataToRender = [];
    for (var i = 0; i < this.selected_regions.length; i++) {
      var countryInventory = this.getCountryInventory(this.selected_regions[i].value);
      this.inventoryDataToRender.push(countryInventory);
    }
    return this.inventoryDataToRender;
  }

  /* Build ECCB graph data */
  setECCBdata() {
    this.eccbdata = [];
    this.eccb_categories = [];
    var serie = { name: "CO2 emissions (in MT)", data: [] };
    for (var i = 0; i < this.raw_eccb.length; i++) {
      this.eccb_categories.push(this.raw_eccb[i]["value_date"]);
      if (this.raw_eccb[i]["total_ets_emissions"] === "") {
        serie.data.push(null);
      } else {
        var serie_value = parseFloat(this.raw_eccb[i]["total_ets_emissions"]);
        serie_value = serie_value / 1000000;
        serie_value = serie_value.toFixed(2);
        if (this.raw_eccb[i].value_date === "2021-01-03") {
          serie.data.push({ id: "remove_uk", y: parseFloat(serie_value) });
        } else {
          serie.data.push(parseFloat(serie_value));
        }
      }
    }
    this.eccbdata.push(serie);
  }

  /* Get inventory for a specific region. It's used to populate global inventory */
  getCountryInventory(region) {
    var inventory = {};
    inventory.region = region;
    inventory.values = [];
    var filtered = this.raw_monthly_emissions.filter((row) => row["country"] === region);
    var value_nth_month = filtered[filtered.length - 1];
    var value_nth_minus_one_month = filtered[filtered.length - 2];

    for (var i = 0; i < this.list_sectors.length; i++) {
      inventory.values.push({
        sector: this.list_sectors[i]["text"],
        abs_change: this.getAbsoluteChange(value_nth_month, value_nth_minus_one_month, this.list_sectors[i]["value"]),
        rel_change: this.getRelativeChange(value_nth_month, value_nth_minus_one_month, this.list_sectors[i]["value"]),
      });
    }
    return inventory;
  }

  /* Get the absolute change between two consecutive month of a sector KPI */
  getAbsoluteChange(n, m, sector) {
    var change = 0;
    if (n[sector] && m[sector]) {
      change = parseFloat(n[sector]) / 1000000 - parseFloat(m[sector]) / 1000000;
      change = parseFloat(change.toFixed(2));
    }
    return change;
  }
  /* Get the relative change between two consecutive month of a sector KPI */
  getRelativeChange(n, m, sector) {
    var change = 0;
    if (n[sector] && m[sector]) {
      change = (parseFloat(n[sector]) - parseFloat(m[sector])) / parseFloat(m[sector]);
      change = change * 100;
      change = parseFloat(change.toFixed(2));
    }
    return change;
  }

  /* Get the readable sector name */
  getSectorName(sector) {
    for (var i = 0; i < this.list_sectors.length; i++) {
      if (this.list_sectors[i].value === sector) {
        return this.list_sectors[i].text;
      }
    }
    return sector;
  }

  /* Build the list of regions to populate the region selector */
  buildRegionsList() {
    if (this.raw_monthly_emissions.length === 0) {
      return [];
    }
    this.list_regions = [];
    this.list_regions.push({ name: "Regions", value: "Regions", country: [] });
    var date = this.raw_monthly_emissions[0]["value_date"];
    var filtered = this.raw_monthly_emissions.filter((row) => row["value_date"] === date);
    /* BUILD GLOBAL REGIONS */
    for (var i = 0; i < filtered.length; i++) {
      if (filtered[i]["super_region"] === "") {
        var exist = false;
        for (var j = 0; j < this.list_regions[0].country.length; j++) {
          if (filtered[i]["country"] === this.list_regions[0].country[j]["name"]) {
            exist = true;
          }
        }
        if (!exist) {
          this.list_regions[0].country.push({
            name: filtered[i]["country"],
            value: filtered[i]["country"],
          });
        }
      }
    }
    /* BUILD GROUP CONTINENT */
    for (i = 0; i < filtered.length; i++) {
      if (filtered[i]["super_region"] !== "") {
        var region_exist = false;
        var country_exist = false;
        var index_region = 0;
        for (j = 0; j < this.list_regions.length; j++) {
          if (filtered[i]["super_region"] === this.list_regions[j]["name"]) {
            index_region = j;
            region_exist = true;
            if (this.list_regions[j]["countries"]) {
              for (var k = 0; k < this.list_regions[j]["countries"].length; k++) {
                if (this.list_regions[j]["countries"][k]["name"] === filtered[i]["country"]) {
                  country_exist = true;
                }
              }
            }
          }
        }
        if (!region_exist) {
          this.list_regions.push({
            name: filtered[i]["super_region"],
            value: filtered[i]["super_region"],
            country: [{ name: filtered[i]["country"], value: filtered[i]["country"] }],
          });
        } else {
          if (!country_exist) {
            this.list_regions[index_region].country.push({
              name: filtered[i]["country"],
              value: filtered[i]["country"],
            });
          }
        }
      }
    }
  }

  /* Identify last update done on the dataset to display data's freshness */
  setLastUpdate() {
    let last_entry = this.raw_monthly_emissions[this.raw_monthly_emissions.length - 1];
    let last_date = last_entry.value_date;
    if (!last_date || last_date === "") {
      this.last_update = "";
      return;
    }
    var reformated_date = new Date(last_date);
    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][reformated_date.getMonth()];
    var readable_date = month + " " + reformated_date.getDay() + ", " + reformated_date.getFullYear();
    this.last_update = readable_date;
  }
}
