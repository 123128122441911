<template>
  <div class="content">
    <section id="header" class="header_team">
      <b-container>
        <b-row>
          <b-col>
            <h1
              class="header_title"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              At Kayrros, we monitor <br />all physical assets in the world
            </h1>
            <p class="header_subtitle">
              with satellites and other technologies <br />for a greener and
              better future.
            </p>
            <a href="https://www.kayrros.com/contact-us/?utm_campaign=carbon_watch_open_data" target="_blank">
              <b-button
                class="hero_button"
                data-aos="fade-up"
                data-aos-duration="1000"
                >Contact us</b-button
              >
            </a>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="partners_banner">
      <b-container>
        <b-row>
          <b-col md="3" class="partner_container">
            <h2 class="partner_title" style="font-size: 1.2rem">
              In collaboration with
            </h2>
          </b-col>
          <b-col md="3" class="partner_container">
            <a href="https://www.lsce.ipsl.fr/" target="_blank">
              <img
                class="partners_logo"
                src="../assets/img/partners/logo_lsce_bw.png"
                alt="Logo LSCE"
              />
            </a>
          </b-col>
          <b-col md="3" class="partner_container">
            <a href="https://www.tsinghua.edu.cn/en/" target="_blank">
              <img
                class="partners_logo"
                src="../assets/img/partners/logo_tsinghua.png"
                alt="Logo Tsinghua University"
              />
            </a>
          </b-col>
          <b-col md="3" class="partner_container">
            <a href="https://uci.edu/" target="_blank">
              <img
                class="partners_logo"
                src="../assets/img/partners/logo_UCI.png"
                alt="Logo UCI"
              />
            </a>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="our-team">
      <b-container>
        <b-row>
          <b-col>
            <h2
              class="section_title"
              data-aos="fade-down"
              data-aos-duration="800"
            >
              Our mission
            </h2>
            <hr class="sep_title" />
            <p class="introduction_text">
              Kayrros is the leading advanced data analytics company that helps
              market players make better decisions. Kayrros delivers deep
              insight into climate and energy-transition risks and extracts
              value from integrating alternative and market data into solutions
              and products across global energy, natural resource and industrial
              markets. <br />
              Our mission is to give energy and industrial actors the data tools
              they need to optimize operations, tackle the climate challenge,
              navigate the energy transition and stay on top of fast-changing
              markets.
            </p>
          </b-col>
        </b-row>
      </b-container>
      <div class="sep-30"></div>
      <div class="sep-30"></div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  components: { Footer },
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
