<template>
  <!-- DATA VISUALIZATION SECTION -->
  <section id="visualization">
    <b-container>
      <b-row>
        <b-col md="3">
          <SectorSelector data-aos="fade-right" data-aos-duration="800"></SectorSelector>
        </b-col>
        <b-col md="9">
          <GlobalChart data-aos="fade-left" data-aos-duration="800" v-if="$store.state.carbonData.graphDataToRender" :values="$store.state.carbonData.graphDataToRender" :categories="$store.state.carbonData.categories"> </GlobalChart>
        </b-col>
      </b-row>
    </b-container>

    <GlobalInventory></GlobalInventory>
  </section>
</template>

<script>
import { CarbonData } from "@/classes/CarbonData.js";
import SectorSelector from "@/components/visualization/SectorSelector.vue";
import GlobalChart from "@/components/visualization/GlobalChart.vue";
import GlobalInventory from "@/components/visualization/GlobalInventory.vue";
export default {
  components: {
    SectorSelector,
    GlobalChart,
    GlobalInventory,
  },
  data() {
    return {
      carbon_data: new CarbonData(),
    };
  },
  watch: {
    "$store.state.carbonData.selected_sectors": function () {
      this.categories = this.carbon_data.getCategories();
      this.carbon_data.getGraphData();
      this.carbon_data.getTextData();
    },
    "$store.state.carbonData.selected_regions": function () {
      this.categories = this.carbon_data.getCategories();
      this.carbon_data.getGraphData();
      this.carbon_data.getTextData();
    },
  },
  methods: {
    /* Load Global data for Sector/region chart */
    loadMonthlyData() {
      var _this = this;
      this.$papa.parse("/files/data.csv", {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: function (response) {
          _this.carbon_data.raw_monthly_emissions = response.data;
          if (_this.carbon_data.list_regions.length === 0) {
            _this.carbon_data.buildRegionsList();
          }
          _this.$store.state.carbonData = _this.carbon_data;
          _this.categories = _this.carbon_data.getCategories();
          _this.$store.state.carbonData.setLastUpdate();
          _this.carbon_data.getGraphData();
          _this.carbon_data.getTextData();
        },
      });
    },

    /* Load ECCB Data for ECCB */
    loadECCBData() {
      var _this = this;
      this.$papa.parse("/files/eccb.csv", {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: function (response) {
          _this.carbon_data.raw_eccb = response.data;
          _this.carbon_data.setECCBdata();
          _this.$store.state.carbonData = _this.carbon_data;
        },
      });
    },
  },
  created() {
    this.loadMonthlyData();
    this.loadECCBData();
  },
};
</script>

<style lang="scss"></style>
