<template>
  <div id="regions_selector">
    <multiselect
      class="multiselect_regions"
      v-if="$store.state.carbonData.list_regions"
      v-model="$store.state.carbonData.selected_regions"
      :options="$store.state.carbonData.list_regions"
      :multiple="true"
      label="name"
      track-by="name"
      :preselect-first="true"
      group-values="country"
      group-label="name"
      placeholder="Select a region / country"
      selectLabel="Select"
      deselectLabel="Remove this region / country"
    ></multiselect>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: [],
      options: [],
    };
  },
};
</script>

<style lang="scss" scoped></style>
