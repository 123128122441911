<template>
  <section id="footer">
    <b-container>
      <b-row>
        <b-col>
          <p class="align_center">
            <a href="https://www.kayrros.com/contact-us/?utm_campaign=carbon_watch_open_data" target="_blank"
              >Contact</a
            >
            <span class="primary_color"><b> | </b></span>
            <b-button v-b-modal.modal-disclaimer>Disclaimer</b-button>
            <!-- Add a scroll to top button -->
            <b-button class="btn-scroll-top" @click="scrollToTop()"
              ><b-icon icon="arrow-up"></b-icon
            ></b-button>
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-disclaimer"
      title="Disclaimer"
      hide-header-close
      cancel-disabled
      ok-only
    >
      <p>
        The data are generated based on Kayrros data and various public sources,
        and have limitations depending on their application purpose, which is
        under each user’s responsibility. For questions please

        <a
          class="primary_color"
          href="https://www.kayrros.com/contact-us/?utm_campaign=carbon_watch_open_data"
          target="_blank"
          >contact us</a
        >.
      </p>
    </b-modal>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style lang="scss"></style>
