<template>
  <section id="ECCB">
    <b-container>
      <b-row>
        <b-col>
          <div class="sep-30"></div>
          <div class="sep-30"></div>
          <h2
            class="section_title"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            EU Carbon Credit Burn (ECCB)
          </h2>
          <hr class="sep_title" />
          <div class="sep-30"></div>
          <p>
            The ECCB weekly chart displays CO2 emissions at the EU level<sup
              >1</sup
            >
            to get a complete view of physical demand for credits in the ETS
            market.
          </p>
          <div class="sep-30"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ECCBChart
            data-aos="fade"
            data-aos-duration="800"
            v-if="$store.state.carbonData.eccbdata"
            :values="$store.state.carbonData.eccbdata"
            :categories="$store.state.carbonData.eccb_categories"
          ></ECCBChart>
        </b-col>
      </b-row>
      <p style="font-size: 0.7rem">
        <sup>1</sup> UK excluded from 2021 onwards <br />
      </p>

      <div class="sep-30"></div>
      <div class="sep-30"></div>
    </b-container>
  </section>
</template>

<script>
import ECCBChart from "@/components/visualization/ECCBChart.vue";
export default {
  components: {
    ECCBChart,
  },
};
</script>

<style lang="scss" scoped></style>
