<template>
  <div id="eecb_chart"></div>
</template>

<script>
import * as Highcharts from "highcharts/highcharts.js";
import * as Annotations from "highcharts/modules/annotations";
Annotations(Highcharts);
export default {
  props: ["values", "categories"],
  data() {
    return {
      chart: {},
    };
  },
  watch: {
    values() {
      /* Re-render graph on values change */
      if (this.values.length > 0) {
        this.drawChart();
        this.$forceUpdate();
      }
    },
    categories() {
      if (this.categories.length > 0) {
        this.drawChart();
        this.$forceUpdate();
      }
    },
  },
  methods: {
    drawChart() {
      var _this = this;
      this.chart = Highcharts.chart("eecb_chart", {
        events: {
          load: function () {
            this.annotations.forEach(function (annotation) {
              annotation.setControlPointsVisibility(true);
              annotation.cpVisibility = true;
            });
          },
        },
        yAxis: {
          title: {
            text: "CO2 emissions (in MT)",
          },
        },

        xAxis: {
          categories: _this.categories,
          tickInterval: 1,
          overflow: "allow",
          labels: {
            enabled: true,
          },
          plotLines: [
            {
              dashStyle: "Dash",
              color: "#2692D3",
              width: 1,
              value: 104,
              label: {
                text: "UK leaves the EU ETS",
                verticalAlign: "top",
                rotation: 0,
                align: "left",
                y: 15,
                style: {
                  color: "#2692D3",
                },
              },
            },
          ],
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        legend: {
          enabled: true,
        },
        series: _this.values,

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {},
            },
          ],
        },
      });
    },
  },
  mounted() {
    this.drawChart();
  },
};
</script>

<style lang="scss" scoped></style>
