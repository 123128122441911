<template>
  <!-- SEPARATOR WITH TIMERANGE SLIDER -->
  <section id="filters-separator">
    <b-container>
      <b-row>
        <b-col>
          <h2
            class="section_title in_sep"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            CO2 MONTHLY EMISSIONS
          </h2>
          <hr class="sep_title" />
          <p class="sep_desc align_center">
            Our chart below shows the monthly evolutions of CO2 emissions by
            sector and region.
          </p>
          <p class="label_field_range"><b>Select region</b></p>
          <RegionSelector></RegionSelector>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import RegionSelector from "@/components/visualization/RegionSelector.vue";
export default {
  components: { RegionSelector },
};
</script>

<style lang="scss" scoped></style>
