<template>
  <div id="app">
    <Navbar :scrolled="pageScrolled"></Navbar>
    <router-view />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: { Navbar },
  data() {
    return {
      pageScrolled: false,
    };
  },
  methods: {
    onScroll(e) {
      //Monitor scroll for sticky navbar
      this.pageScrolled = e.target.documentElement.scrollTop > 20;
    },
  },
  created: function () {
    // Set page title
    if (this.$route.name === "Home") {
      document.title = "Carbon Watch OS | Home";
    } else if (this.$route.name === "WhoWeAre") {
      document.title = "Carbon Watch OS | Who We Are";
    } else if (this.$route.name === "Methodology") {
      document.title = "Carbon Watch OS | Methodology";
    } else if (this.$route.name === "ETS Scope") {
      document.title = "Carbon Watch OS | ETS Scope";
    }
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,400&display=swap");
@import "./assets/css/global.scss";
</style>
