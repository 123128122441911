<template>
  <!-- OVERVIEW CHART -->
  <section class="chart_section">
    <div id="overview_chart"></div>
  </section>
</template>

<script>
import * as Highcharts from "highcharts/highcharts.js";
export default {
  props: ["values", "categories"],
  data() {
    return {
      chart: {},
      colors: [
        "#002642",
        "#840032",
        "#e59500",
        "#a77b7b",
        "#02040f",
        "#028090",
        "#6cb164",
        "#456990",
        "#f45b69",
        "#41ead4",
      ],
    };
  },
  watch: {
    values() {
      /* Re-render graph on values change */
      if (this.values.length > 0) {
        for (var i = 0; i < this.values.length; i++) {
          this.values[i].color = this.colors[i % this.colors.length];
          this.values[i].lineColor = this.colors[i % this.colors.length];
        }
        this.drawChart();
        this.$forceUpdate();
      }
    },
    categories() {
      if (this.categories.length > 0) {
        for (var i = 0; i < this.values.length; i++) {
          this.values[i].color = this.colors[i % this.colors.length];
          this.values[i].lineColor = this.colors[i % this.colors.length];
        }
        this.drawChart();
        this.$forceUpdate();
      }
    },
  },
  methods: {
    drawChart() {
      var _this = this;
      this.chart = Highcharts.chart("overview_chart", {
        yAxis: {
          title: {
            text: "CO2 Emissions (in MT)",
          },
        },

        xAxis: {
          categories: _this.categories,
          tickInterval: 1,
          overflow: "allow",
          labels: {
            enabled: true,
          },
        },
        /*         tooltip: {
          useHTML: true,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          formatter: function () {
            return (
              "<div class='custom_tooltip' style='background-color:" +
              this.series.color +
              ";'>Quarter : <b>" +
              _this.values[this.x][0] +
              "</b><br> Biomass Carbon Stock : <b>" +
              this.y +
              "</b></div>"
            );
          },
        }, */
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        legend: {
          enabled: true,
        },
        series: _this.values,

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {},
            },
          ],
        },
      });
    },
  },
  mounted() {
    this.drawChart();
  },
};
</script>

<style lang="scss"></style>
