<template>
  <div class="content">
    <section id="header" class="header_ets_scope">
      <b-container>
        <b-row>
          <b-col>
            <h1
              class="header_title"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              ETS Scope
            </h1>

            <a href="https://www.kayrros.com/contact-us/?utm_campaign=carbon_watch_open_data" target="_blank">
              <b-button
                class="hero_button"
                data-aos="fade-up"
                data-aos-duration="1000"
                >Contact us</b-button
              >
            </a>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="our-methods">
      <b-container>
        <b-row>
          <b-col>
            <h2
              class="section_title"
              data-aos="fade-down"
              data-aos-duration="800"
            >
              ETS Scope
            </h2>
            <hr class="sep_title" />
            <div class="sep-30"></div>
            <p class="introduction_text">
              Carbon Watch relies on Kayrros expertise in handling
              unconventional and large realtime datasets (such as satellite
              imagery and geolocation data) to infer activity levels in key
              CO2-intensive sectors including Power Generation, Heavy Industry,
              Ground Transport and Aviation.
            </p>
            <p class="introduction_text">
              This results in reliable, near-realtime, global measurements of
              CO2 emissions by sector and country. This table summarizes the
              scope of the emissions that we cover with Kayrros Carbon Watch.
              The early-access version focuses on the scope of the ETS.
            </p>
          </b-col>
        </b-row>
      </b-container>
      <div class="sep-30"></div>
      <b-container>
        <b-row>
          <b-col>
            <b-table
              data-aos="fade-up"
              data-aos-duration="800"
              id="ets_scope_table"
              head-variant="dark"
              striped
              hover
              outlined
              responsive
              :items="items"
              :fields="fields"
              class="align_center"
            >
              <template #head(sector)="data">
                <p class="align_left">{{ data.label }}</p>
              </template>
              <template #cell(sector)="data">
                <p class="align_left">
                  <img
                    v-if="data.item.sector === 'Power'"
                    class="table_sector_icon"
                    src="../assets/img/icons/nuclear-plant-d.png"
                    alt="Power icon"
                  />
                  <img
                    v-else-if="data.item.sector === 'Industry'"
                    class="table_sector_icon"
                    src="../assets/img/icons/factory-d.png"
                    alt="Industry icon"
                  />
                  <img
                    v-else-if="data.item.sector === 'Domestic Aviation'"
                    class="table_sector_icon"
                    src="../assets/img/icons/plane-d.png"
                    alt="Aviation icon"
                  />
                  <img
                    v-else-if="data.item.sector === 'International Aviation'"
                    class="table_sector_icon"
                    src="../assets/img/icons/plane-d.png"
                    alt="Aviation icon"
                  />
                  <img
                    v-else-if="data.item.sector === 'Ground Transport'"
                    class="table_sector_icon"
                    src="../assets/img/icons/delivery-truck-d.png"
                    alt="Ground transport icon"
                  />
                  <img
                    v-else-if="data.item.sector === 'Residential'"
                    class="table_sector_icon"
                    src="../assets/img/icons/country-house-d.png"
                    alt="Residential icon"
                  />
                  <img
                    v-else-if="data.item.sector === 'Shipping'"
                    class="table_sector_icon"
                    src="../assets/img/icons/cargo-boat-d.png"
                    alt="Shipping icon"
                  />
                  <b>{{ data.item.sector }}</b>
                </p>
              </template>
              <template #cell(ets_scope)="data">
                <p class="align_center" style="padding-top: 7px">
                  <b-icon
                    v-if="data.item.ets_scope === 1"
                    icon="check-circle-fill"
                    scale="2"
                    style="color: #2692d3 !important"
                    variant="success"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="x-circle-fill"
                    scale="2"
                    variant="danger"
                  ></b-icon>
                </p>
              </template>
              <template #cell(early_scope)="data">
                <p class="align_center" style="padding-top: 7px">
                  <b-icon
                    v-if="data.item.early_scope === 1"
                    icon="check-circle-fill"
                    scale="2"
                    style="color: #2692d3 !important"
                    variant="success"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="x-circle-fill"
                    scale="2"
                    variant="danger"
                  ></b-icon>
                </p>
              </template>
              <template #cell(full_scope)="data">
                <p class="align_center" style="padding-top: 7px">
                  <b-icon
                    v-if="data.item.full_scope === 1"
                    icon="check-circle-fill"
                    scale="2"
                    style="color: #2692d3 !important"
                    variant="success"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="x-circle-fill"
                    scale="2"
                    variant="danger"
                  ></b-icon>
                </p>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
      <div class="sep-30"></div>
      <div class="sep-30"></div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  components: { Footer },
  data() {
    return {
      items: [
        { sector: "Power", ets_scope: 1, early_scope: 1, full_scope: 1 },
        { sector: "Industry", ets_scope: 1, early_scope: 1, full_scope: 1 },
        {
          sector: "Domestic Aviation",
          ets_scope: 1,
          early_scope: 1,
          full_scope: 1,
        },
        {
          sector: "International Aviation",
          ets_scope: 0,
          early_scope: 0,
          full_scope: 1,
        },
        {
          sector: "Ground Transport",
          ets_scope: 0,
          early_scope: 1,
          full_scope: 1,
        },
        { sector: "Residential", ets_scope: 0, early_scope: 0, full_scope: 1 },
        { sector: "Shipping", ets_scope: 0, early_scope: 0, full_scope: 1 },
      ],
      fields: [
        { key: "sector", label: "Sector", sortable: false },
        { key: "ets_scope", label: "Included in the ETS", sortable: false },
        {
          key: "early_scope",
          label: "Scope of the Early Access Version",
          sortable: false,
        },
        {
          key: "full_scope",
          label: "Scope of the Full Product",
          sortable: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#ets_scope_table {
  p {
    margin-bottom: 0;
    padding-bottom: 0 !important;
  }
}
.table_sector_icon {
  width: 40px;
  margin-right: 20px;
  height: auto;
}
</style>
