import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import WhoWeAre from "../views/WhoWeAre.vue";
import Methodology from "../views/Methodology.vue";
import ETSScope from "../views/ETSScope.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/who-we-are",
    name: "WhoWeAre",
    component: WhoWeAre,
  },
  {
    path: "/methodology",
    name: "Methodology",
    component: Methodology,
  },
  {
    path: "/ets-scope",
    name: "ETS Scope",
    component: ETSScope,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
