<template>
  <!-- NAVBAR -->
  <b-navbar
    v-bind:class="[scrolled ? 'page-scrolled' : '']"
    id="navbar"
    toggleable="lg"
    fixed="top"
  >
    <b-navbar-brand href="/">
      <img
        class="brand_logo"
        src="../assets/img/kayrros_logo.png"
        alt="Logo Kayrros"
      />
      <img
        class="brand_logo_light"
        src="../assets/img/kayrros_logo_light.png"
        alt="Logo Kayrros"
      />
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="/">Home</b-nav-item>
        <b-nav-item href="/who-we-are">Who we are</b-nav-item>
        <b-nav-item href="/methodology">Methodology</b-nav-item>
        <b-nav-item href="/ets-scope">ETS Scope</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  props: ["scrolled"],
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>
