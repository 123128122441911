import { render, staticRenderFns } from "./CTASection.vue?vue&type=template&id=287e5605&scoped=true&"
import script from "./CTASection.vue?vue&type=script&lang=js&"
export * from "./CTASection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "287e5605",
  null
  
)

export default component.exports