<template>
  <div id="sectors_selector_container">
    <p class="section_title primary_color"><b>Sectors</b></p>
    <div v-if="$store.state.carbonData.list_sectors">
      <b-form-group>
        <b-form-checkbox-group v-model="$store.state.carbonData.selected_sectors" :options="$store.state.carbonData.list_sectors" stacked buttons></b-form-checkbox-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
