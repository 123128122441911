import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VuePapaParse from "vue-papa-parse";
import VueGtag from "vue-gtag";
Vue.use(VuePapaParse);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

import AOS from "aos";
import "aos/dist/aos.css";

Vue.use(
  VueGtag,
  {
    config: { id: "UA-137561778-1" },
    pageTrackerTemplate(to) {
      return {
        page_path: to.fullPath,
        page_title: "Carbon Watch OS - " + to.name,
      };
    },
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  created() {},
  router,
  store,
  render: function (h) {
    return h(App);
  },
  mounted() {
    AOS.init();
  },
}).$mount("#app");
