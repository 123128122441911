<template>
  <section id="section_cta">
    <b-container>
      <b-row>
        <b-col md="8">
          <p class="cta_get_in_touch">
            As a <b>Carbon trader or analyst</b>, you are interested in getting
            <b>more timely or granular data</b> (by sector or country)?
          </p>
        </b-col>
        <b-col md="4">
          <p class="align_center" style="margin-top: 10px">
            <a href="https://www.kayrros.com/contact-us/?utm_campaign=carbon_watch_open_data" target="_blank">
              <b-button data-aos="fade-down" data-aos-duration="800">
                Get in touch
              </b-button>
            </a>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
