<template>
  <!-- PARTNERS BANNER SECTION -->
  <section id="partners_banner">
    <b-container>
      <b-row>
        <b-col md="3" class="partner_container">
          <a href="https://kayrros.com" target="_blank">
            <img
              class="partners_logo"
              src="../assets/img/partners/logo_kayrros_bw.png"
              alt="Logo Kayrros"
            />
          </a>
        </b-col>
        <b-col md="3" class="partner_container">
          <a href="https://www.lsce.ipsl.fr/" target="_blank">
            <img
              class="partners_logo"
              src="../assets/img/partners/logo_lsce_bw.png"
              alt="Logo LSCE"
            />
          </a>
        </b-col>
        <b-col md="3" class="partner_container">
          <a href="https://www.tsinghua.edu.cn/en/" target="_blank">
            <img
              class="partners_logo"
              src="../assets/img/partners/logo_tsinghua.png"
              alt="Logo Tsinghua University"
            />
          </a>
        </b-col>
        <b-col md="3" class="partner_container">
          <a href="https://uci.edu/" target="_blank">
            <img
              class="partners_logo"
              src="../assets/img/partners/logo_UCI.png"
              alt="Logo UCI"
            />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
