import Vue from "vue";
import Vuex from "vuex";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    filters: {
      regions: ["Europe"],
      timerange: "Daily",
    },
    carbonData: {},
    timerange: {},
    country: "",
    list_countries: ["World"],
  },
  mutations: {
    updateCarbonData(state, c) {
      state.carbonData = c;
    },
    updateTimerange(state, t) {
      state.timerange = t;
    },
    updateCountry(state, c) {
      state.country = c;
    },
    updateListCountries(state, l) {
      state.list_countries = l;
    },
  },
  actions: {},
  modules: {},
});
