<template>
  <div class="content">
    <section id="header">
      <b-container>
        <b-row>
          <b-col>
            <h1
              class="header_title"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              Our Methodology
            </h1>

            <a href="https://www.kayrros.com/contact-us/?utm_campaign=carbon_watch_open_data" target="_blank">
              <b-button
                class="hero_button"
                data-aos="fade-up"
                data-aos-duration="1000"
                >Contact us</b-button
              >
            </a>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section id="our-methods">
      <b-container>
        <b-row>
          <b-col>
            <h2
              class="section_title"
              data-aos="fade-down"
              data-aos-duration="800"
            >
              Methodology
            </h2>
            <hr class="sep_title" />
            <div class="sep-30"></div>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="methodology_container">
        <b-row>
          <b-col sm="4">
            <div
              class="methodology_card"
              data-aos="fade"
              data-aos-duration="1000"
            >
              <img
                class="methodology_icon"
                src="../assets/img/icons/nuclear-plant-d.png"
                alt="Power icon"
              />
              <p class="methodology_title">Power</p>
              <p class="methodology_desc">
                Based on direct electricity generation measurements, we built a
                bottom-up reconstruction of the sector’s regulated emissions
              </p>
            </div>
          </b-col>
          <b-col sm="4">
            <div
              class="methodology_card"
              data-aos="fade"
              data-aos-duration="1000"
            >
              <img
                class="methodology_icon"
                src="../assets/img/icons/factory-d.png"
                alt="Industry icon"
              />
              <p class="methodology_title">Industry</p>
              <p class="methodology_desc">
                Using satellite imagery, we derive activity signals on the
                largest emitting assets (cement, steel…).
              </p>
            </div>
          </b-col>

          <b-col sm="4">
            <div
              class="methodology_card"
              data-aos="fade"
              data-aos-duration="1000"
            >
              <img
                class="methodology_icon"
                src="../assets/img/icons/country-house-d.png"
                alt="Residential icon"
              />
              <p class="methodology_title">Residential</p>
              <p class="methodology_desc">
                We derive emissions by combining weather data, density maps and
                demand for natural gas (Planned in future updates).
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="4">
            <div
              class="methodology_card"
              data-aos="fade"
              data-aos-duration="1000"
            >
              <img
                class="methodology_icon"
                src="../assets/img/icons/delivery-truck-d.png"
                alt="Ground transport icon"
              />
              <p class="methodology_title">Ground transport</p>
              <p class="methodology_desc">
                We compute emissions from car and truck journeys detected from
                the analysis of live geolocation data.
              </p>
            </div>
          </b-col>
          <b-col sm="4">
            <div
              class="methodology_card"
              data-aos="fade"
              data-aos-duration="1000"
            >
              <img
                class="methodology_icon"
                src="../assets/img/icons/plane-d.png"
                alt="Plane icon"
              />
              <p class="methodology_title">Domestic aviation</p>
              <p class="methodology_desc">
                We measure emissions based on flight data down to the aircraft
                level.
              </p>
            </div>
          </b-col>
          <b-col sm="4">
            <div
              class="methodology_card"
              data-aos="fade"
              data-aos-duration="1000"
            >
              <img
                class="methodology_icon"
                src="../assets/img/icons/cargo-boat-d.png"
                alt="Shipping icon"
              />
              <p class="methodology_title">Shipping</p>
              <p class="methodology_desc">
                We pinpoint emissions by tracking ship journeys worldwide (Planned in future updates).
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="sep-30"></div>
      <div class="sep-30"></div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  components: { Footer },
};
</script>

<style lang="scss"></style>
